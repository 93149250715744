body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background-color: #181818;
}

/* main container */
.content-container {
  display: block;
  flex: auto;
  background-color: #ffffff;

}

.container {
  padding: 3% 3% 3% 0%;
  margin: auto;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: row;
  height: 100vh;
  max-width: 1980px;
  padding-left: 80px;
  padding-right: 80px;
}

/* header top of page */
.header {
  border-radius: 10px 10px 0px 0px;
  padding: 0px 10px 0px 10px;
  align-items: center;
  display: flex;
  background-color: #202020;
  justify-content: space-between;
}

.login-box {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  padding-left: 10px;
  color:#ffffff; 
}

.underline:hover {
  text-decoration: underline;
  cursor: pointer;
}

.login-box h2 {
  color: #ffffff;
}

/* LOGO */
.logo {
  display: flex;
  background-color: #ff6c2d;
  border-radius: 10px 0px 10px 0px;
  align-content: flex-start;
  max-height: 75%;
  text-shadow: 1px 2px 2px rgb(0, 0, 0);
  outline-color: black;
  padding-right: 10px;
  padding-left: 10px;

}

.logo h1 {
  color: #ffffff;
  text-transform: uppercase;
}

/* SWITCH */

.switch {
  display: flex;
}

.switch button {
  width: 200px;
  margin-top: 5px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  border: 1px solid #ffffff;
  color: #ffffff;
  transition: all 0.3s linear;
  border-radius: 7px;
  background-color: transparent;
}

.switch button:hover {
  background-color: #fefefe;
  color: #3b3b3b;
  cursor: pointer;
}

.darkmode button {
  width: 120px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  border: 1px solid #ffffff;
  color: #ffffff;
  transition: all 0.3s linear;
  border-radius: 7px;
  background-color: transparent;
}

.darkmode button:hover {
  background-color: #fefefe;
  color: #3b3b3b;
  cursor: pointer;
}

/* stocks extra */

.stocksextra-top{
  background-color: rgb(255, 255, 255);
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: baseline;
  padding: 0px 0px 14px 0px;
}

.stocks-extra {
  background-color: #FFFFFF;
  padding: 14px 14px 14px 14px;
}

.stock_symbol_extra {
  font-size: 24px;
  font-weight: bold;
  color: #e76f51;
  
}

.stock_long_name_extra {
  font-size: 18px;
  font-weight: bold;
}

.stocksextra-footer span + span {
    margin-left: 10px;
}

.stocksextra-footer span:hover {
  filter: alpha(opacity=80);
  color: #e76f51;
}

.test :hover {
  filter: alpha(opacity=80);
  opacity: 1;
}

.profil {
  background-color: #f6f6f6;
  padding: 1%;
  }

.stocks-extra-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  background-color: #f6f6f6;
  padding: 1%;
  }
  
  .div1 {  grid-area: 1 / 1 / 2 / 2;  }
  .div2 { grid-area: 1 / 2 / 2 / 3; }
  .div3 { grid-area: 1 / 3 / 2 / 4; }
  .div4 { grid-area: 1 / 4 / 2 / 5; }
  .div5 { grid-area: 2 / 1 / 3 / 2; }
  .div6 { grid-area: 2 / 2 / 3 / 3; }
  .div7 { grid-area: 2 / 3 / 3 / 4; }
  .div8 { grid-area: 2 / 4 / 3 / 5; }

.extra-1, .extra-2, .extra-3, .extra-4, .extra-5, .extra-6, .extra-7, .extra-8 {
  background-color: rgb(233, 233, 233);
  padding: 0.5rem;
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.25%);
}

.extra-4, .extra-8 {
  visibility: hidden;
}

/* PORTFOLIO */
.portfolio {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  }
  
  .pdiv1 { grid-area: 1 / 2 / 2 / 3; }
  .pdiv2 { grid-area: 2 / 2 / 3 / 3; }
  .pdiv3 { grid-area: 1 / 1 / 3 / 2; }

.portfolio_table {
  text-align: left;
  table-layout: fixed;
  width: 100%;
}

.portfolio_table th{
  text-align: left;
  table-layout: fixed;
  width: 100%;
}

.portfolio_table td {
  border-bottom:  solid 1px;
  border-color: #cccccc;
  vertical-align: bottom;
}

.portfolio-table tr {
  background-color: #fefefe;
    transition: background-color 1s;
}

.portfolio-table tr:hover {
  background-color: rgb(228, 228, 228);
}

/* stocks container */
.stocks {
  background-color: #FFFFFF;
  padding: 14px 14px 14px 14px;
}

.stocks th {
  padding-top: 12px;
  padding-bottom: 6px;
  text-align: left;
  background-color: #f6f6f6;
  color: black;
  border-bottom: solid 1px;
}

.stocks td {
  border-bottom:  solid 1px;
  border-color: #cccccc;
  vertical-align: bottom;
}

.stocks td:first-child {
  max-width: 10px;
}

.stocks tr {
  background-color: #fefefe;
    transition: background-color 1s;
}

.stocks tr:hover {
  background-color: rgb(228, 228, 228);
}

.stocks h1 {
  font-size: smaller;
  margin: 0;
}

.stocks-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.stocks-table2 {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.stock_symbol {
  font-size: 16px;
  font-weight: bold;
  color: #e76f51;
}

.td_rank {
  font-size: large;
  font-weight: bold;
  display: flex;
  justify-content: left;
}

/* STOCK TABLE HYPERINK STYLING */ 
a:link {
  color: #e76f51;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: #e76f51;
}

/* mouse over link */
a:hover {
  color: gray
}

/* selected link */
a:active {
  color: #e76f51;
}

.status { 
  grid-area: 3 / 1 / 4 / 2; 
  background-color: #212121;
  display: flex;
  justify-content: center;
}

.status_text {
  color: #717070;
  margin-bottom: 5px;
}

.status_text online {
  color:chartreuse;
  margin-bottom: 5px;
}

.status .thumbnail h4 {
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  color: #e76f51;
}

.thumbnail {
  width: 25%;
  text-align: center;
  float: left;
  margin-top: 2%;
  margin-bottom: 2%;
}

.thumbnail_extra {
  color: #ffffff;
}

.join { 
  padding: 20px 20px;
  grid-area: 4 / 1 / 5 / 2; 
  background-color: #ff6c2d;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.join p {
  color: white;
  letter-spacing: 4px;
}

.join button {
  width: 160px;
  margin-top: 5px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  border: 1px solid #ffffff;
  color: #ffffff;
  transition: all 0.3s linear;
  border-radius: 7px;
  background-color: transparent;
}

.join input {
  width: 200px;
  margin-top: 5px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
  padding-top: 20px;
  padding-right: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 2px;
  border: 1px solid #ffffff;
  color: #ffffff;
  transition: all 0.3s linear;
  border-radius: 7px;
  background-color: transparent;
}

.join input::placeholder {
  color: white;
  letter-spacing: 4px;
}

.join button:hover {
  background-color: #fefefe;
  color: #3b3b3b;
  cursor: pointer;
}

.credits { 
  grid-area: 5 / 1 / 6 / 2; 
  background-color: #212121;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.credits_left span:hover {
  filter: alpha(opacity=80);
  color: #e76f51;
}

.credits_left span {
  margin-left: 1.5rem;
}

.credits_left {
  display:flex;
}

.credits_right {
  display:flex;
}

.credits img {
  height: 20px;
  padding: 10px;
  opacity: .5;
  padding-left: 1rem;
  padding-right: 1rem;
}

img:hover {
  filter: alpha(opacity=80);
  opacity: 1;
}

.copyright { 
  grid-area: 6 / 1 / 7 / 2; 
  background-color: #3a3a3a;
  display: flex;
  justify-content: center;
  align-items: center;

}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.spinner div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #e76f51;
  animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.spinner div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.spinner div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.spinner div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes spinner {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}
